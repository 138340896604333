import React from 'react';
import '../assets/css/main.css'

function Copyright() {
  return (
    <div id="copyright">
    <ul><li>&copy; TheGrosjeans</li><li>Design: <a href="https://html5up.net">HTML5 UP</a></li></ul>
  </div>

  );
}

export default Copyright;